export function Footer() {
  return <>
    <div>
      <a className="back_to_top" href="#">Back to top</a>
    </div>

    <footer className="foot">
      <p>All rights reserved. fanzengau.com</p>
    </footer>
  </>
}